<template>
  <div class="row mt-2">
    <div class="col s12 m8 offset-m2 ">
      <div class="info-box">
        <h1 class="mt-0">404</h1>
        <div class="center">
          <h6>Die angeforderte {{ resource }} konnte nicht gefunden werden</h6>
          <p v-if="isFile">Möglicherweise wurde die Datei gelöscht.</p>
          <p v-else>Bitte überprüfen Sie die eingegebene Adresse auf Tippfehler.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    isFile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    resource() {
      return this.isFile? "Datei": "Seite";
    }
  }
};
</script>

<style module type="scss">


</style>
